import { Link } from "gatsby";
import * as React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/custom.scss";

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler, crmid) => {
  let result = [];
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    "query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id}) { id, slug, search_type, department } }",
    "variables": { "crm_id": [crmid] }
  });
  console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  result[0] = JSON.stringify(res.data)
  handler(result) //update state var
}

const NotFoundPage = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false)
  const [propdata, setPropdata] = React.useState("") // false

  React.useEffect(() => {

    // unpublished properties 404 page
    if(props?.publish == false){
      setShowtemplate(true)
    }
    //we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
    let regex = ""; //you can modify the preg as per proj feed
    if (props?.location?.pathname) {
      let crm_id = "";
      if (props?.location?.pathname?.length == 10) {
        regex = "\[a-zA-Z]{2}-[a-zA-Z]{1}-[0-9]{4}";  //you can modify the preg as per proj feed
      } else if(props?.location?.pathname?.length == 7){
				regex = "\[0-9]{6}";  //you can modify the preg as per proj feed
			}
      crm_id = (props?.location?.pathname).match(regex);
      //mke sure state value is empty and we have proper url to proceed
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]) // call the function to fetch data from strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata)
        let property_arr = property_data.data.properties
        if (property_arr.length > 0) {
          let property = property_arr[0]
          //set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          if (property.department == "residential") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/property-to-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          } else if (property.department == "commercial") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/property-to-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          } else if (property.department == "short-lettings") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/property-to-rent/${property.slug}-${property.id}`, {
                  replace: true,
              })
              }
            }
          }
        } else {
          setShowtemplate(true) // show 404 page template
        }
      } else {
        setShowtemplate(true) // show 404 page template
      }
    }
  }, [propdata])

    return (
      <div>
      {showtemplate &&
        <Layout>
          <Seo title="404: Not found" />
          <div className="error-page">
            <h1>We're sorry, the page you were looking for cannot be found.</h1>
            <p>This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
            <Link to="/contact-arabian-estates/" className="btn">Contact Us</Link>
          </div>
        </Layout>
      }
      </div>
    )
}
export default NotFoundPage
